import React from 'react';
import { graphql, HeadFC, PageProps } from 'gatsby';
import PageHeader from '../../components/PageHeader/PageHeader';
import GuideBlocks from '../../components/GuideBlocks/GuideBlocks';
import { getArticles, getDefaultSEO, getFeaturedImageDataFromWpPost } from '../../utils';
import PaginationV2 from '../../components/PaginationV2/PaginationV2';
import Seo from '../../components/Seo';
import { HT_PAGE_CATEGORY_ARCHIVE_PAGE, MAX_NUMBER_OF_POSTS_PER_PAGE } from '../../constants';
import HTPageWrapper from '../../wrappers/HTPageWrapper';

interface IAuthorArchiveTemplateProps {
  posts: Queries.WpPostConnection;
  author: Queries.WpUser;
}

const AuthorArchiveTemplate = ({
  data,
  pageContext,
  location,
}: PageProps<IAuthorArchiveTemplateProps>) => {
  const {
    posts: { nodes },
    author,
  } = data;
  const currentPageIndex = (pageContext as any)?.pageNumber - 1 || 0;
  const uri = (pageContext as any)?.uri || '/';
  let posts: Queries.WpPost[] = [];
  if (nodes && Array.isArray(nodes)) {
    posts = nodes;
  }

  const articles = getArticles(posts).map((article) => ({
    ...article,
    thumbnail: {
      ...article.thumbnail,
      src: article.thumbnail.src,
    },
  }));

  const totalNumberOfPages = (pageContext as any)?.totalNumberOfPages || 0;
  const title = `${author.firstName}'s Latest Posts`;

  return (
    <HTPageWrapper category={"Author, " + HT_PAGE_CATEGORY_ARCHIVE_PAGE} title={title} location={location}>
      <PageHeader showGraphic title={title} />
      <GuideBlocks articles={articles} />
      {totalNumberOfPages > 0 && (
        <PaginationV2
          currentPageIndex={currentPageIndex}
          basePath={uri}
          pageSize={MAX_NUMBER_OF_POSTS_PER_PAGE}
          numberOfPages={totalNumberOfPages}
        />
      )}
    </HTPageWrapper>
  );
};

export default AuthorArchiveTemplate;

export const Head: HeadFC<IAuthorArchiveTemplateProps> = ({ pageContext, data }) => {
  const { author, posts } = data;
  const { pageNumber, totalNumberOfPages, uri } = pageContext as any;
  const pageTitle = `${author.name}, ${author.description} - Page ${pageNumber} of ${
    totalNumberOfPages + 1
  }`;
  const socialTitle = pageTitle;
  const ogDescription = author?.seo?.metaDesc || '';
  const includeCollectionPageType = true;
  const pageSuffix = `page/${pageNumber}/`;
  let pathname = uri;
  if (!pathname.endsWith('/')) pathname += '/';
  pathname += pageSuffix;
  const seo = getDefaultSEO(
    pathname,
    pageTitle,
    socialTitle,
    ogDescription,
    includeCollectionPageType,
  );
  const firstPost = posts.nodes[0];
  const featuredImageData = firstPost ? getFeaturedImageDataFromWpPost(firstPost) : null;
  return <Seo title={pageTitle} wpSeo={seo} featuredImageData={featuredImageData} />;
};

export const query = graphql`
  query AuthorArchivePosts($id: String, $start: Int, $limit: Int) {
    posts: allWpPost(
      filter: { author: { node: { id: { eq: $id } } } }
      limit: $limit
      skip: $start
      sort: { date: DESC }
    ) {
      nodes {
        title
        uri
        dateGmt
        excerpt
        featuredImage {
          node {
            caption
            width
            height
            sourceUrl
          }
        }
        author {
          node {
            uri
            name
          }
        }
      }
    }
    author: wpUser(id: { eq: $id }) {
      description
      firstName
      name
      seo {
        metaDesc
      }
    }
  }
`;
